import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import NavTab from './NavTab'
import ProjectList from './ProjectList'
import "./Projects.css"
function Projects() {
    return (
        <div>
            <Helmet>
                <title>Projects | Prashant Singh Chauhan</title>
            </Helmet>
           <NavTab/>
           <ProjectList/>
           <Footer/>
        </div>
    )
}

export default Projects
