import React from 'react'
import "./Blogs.css"
import NavTab from './NavTab'
import BlogList from './BlogList'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
function Blogs() {
    return (
        <div>
                  <Helmet>
                <title>Blogs | Prashant Singh Chauhan</title>
            </Helmet>
            <NavTab/>
            <BlogList />
           <Footer/>  
        </div>
    )
}

export default Blogs
