import React from 'react'
import "./Home.css"
import Hero from '../images/hero.svg'
import Frittr from '../images/frittr.png'
import Windys from '../images/windys.png'
import Dyme from '../images/dyme.png'
import {Helmet} from "react-helmet";
import NavTab from './NavTab'
import Skills from './Skills'
import Footer from './Footer'
import { Link } from 'react-router-dom'
function Home() {
    return (
      <div>
        <Helmet>
          <title>Prashant Singh Chauhan</title>
        </Helmet>
        <NavTab />

        {/* <Intro/> */}
        <div className="introParent">
          <div className="text">
            <h1>Hi there!</h1>
            <h2>
              I'm Prashant <span>A Full Stack Developer</span>.
            </h2>
            <p>
              I am a seasoned MERN Stack Developer with 5 years of professional
              experience ranging from US Big4 to small scale startups.
            </p>{" "}
            <p>
              {" "}
              Your <strong>next-door friendly 'can-do-it'</strong> guy.
            </p>
          </div>
          <div className="imj">
            <img src={Hero} alt="Hero" />
          </div>
        </div>

        {/* ProjectsSection */}
        <div className="project">
          <h2>
            Hot <span>Projects 🔥</span>{" "}
          </h2>
          <div className="cardParent">
            <div className="card">
              <h3>Dyme</h3>
              <small>11th September 2022</small>
              <div className="proj-preview">
                <img src={Dyme} alt="Dyme" />
              </div>
              <p>
                Social Media app which makes you money based on your content.{" "}
                <i>[ P.S: still in alpha stage.]</i>
              </p>
              <div className="techStack">
                <h6>Firebase</h6>
                <h6>Chakra UI</h6>
                <h6>MERN</h6>
              </div>
              <div className="code">
                <a
                  href="https://dymesocial.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="live"
                  style={{ padding: "0.25rem 1.5rem" }}
                >
                  <strong> Join Dyme</strong>
                </a>
                <a
                  //   href=""
                  //   target="_blank"
                  //   rel="noreferrer noopener"
                  className="source"
                >
                  Private Project
                </a>
              </div>
            </div>

            <div className="card">
              <h3>Frittr</h3>
              <small>11th September 2022</small>
              <div className="proj-preview">
                <img src={Frittr} alt="Frittr" />
              </div>
              <p>Social Media app for Foodies, made using React JS</p>
              <div className="techStack">
                <h6>Redux Toolkit</h6>
                <h6>MERN</h6>
              </div>
              <div className="code">
                <a
                  href="https://frittr.netlify.app/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="live"
                >
                  View Live
                </a>
                <a
                  href="https://github.com/percius47/fritter"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="source"
                >
                  View Source
                </a>
              </div>
            </div>

            <div className="card">
              <h3>Windy's</h3>
              <small>11th September 2022</small>
              <div className="proj-preview">
                <img src={Windys} alt="Windys" />
              </div>
              <p>Food Ordering app, made using React JS</p>
              <div className="techStack">
                <h6>MERN</h6>
                <h6>Razorpay API</h6>
              </div>

              <div className="code">
                <a
                  href="https://windys.netlify.app/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="live"
                >
                  View Live
                </a>
                <a
                  href="https://github.com/percius47/ecommerce-mockman"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="source"
                >
                  View Source
                </a>
              </div>
            </div>

            {/* C3 */}
          </div>
          {/* Cardparent End */}
          <Link exact to="/projects" className="viewA">
            View All
          </Link>
        </div>

        {/* <Blogs/> */}

        <div className="blog">
          <h2>
            My <span>Blogs</span>{" "}
          </h2>
          <div className="cardParentB">
            <div className="cardB">
              <h3>Debouncing and Throttling in JavaScript</h3>
              <small>6th June 2022</small>
              <p>
                What are they and how do we use them to implement optimization
                in our web applications?
              </p>
              <div className="codeB">
                <a
                  href="https://prashantxwrites.hashnode.dev/debouncing-and-throttling-in-javascript"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="liveB"
                >
                  Read
                </a>
              </div>
            </div>

            <div className="cardB">
              <h3>Closures in JavaScript</h3>
              <small>16th May 2022</small>
              <p>
                A short read on the concept and applications of Closures in
                JavaScript
              </p>

              <div className="codeB">
                <a
                  href="https://prashantxwrites.hashnode.dev/closures-in-javascript"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="liveB"
                >
                  Read
                </a>
              </div>
            </div>

            <div className="cardB">
              <h3>Memoization in JavaScript</h3>
              <small>15th May 2022</small>
              <p>
                Short read to describe the optimization technique of Memoization
                in JavaScript.
              </p>

              <div className="codeB">
                <a
                  href="https://prashantxwrites.hashnode.dev/memoization-in-javascript"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="liveB"
                >
                  Read
                </a>
              </div>
            </div>

            {/* C3 */}
          </div>
          {/* Cardparent End */}
          <Link exact to="/blogs" className="viewB">
            View All
          </Link>
        </div>
        <Skills />

        <Footer />
      </div>
    );
}

export default Home
