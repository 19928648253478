import React from 'react'
import  "./BlogList.css"
import {FaAngleUp } from "react-icons/fa"
import {animateScroll as scroll} from "react-scroll";

const projects=[
    {
        title: "Debouncing & Throttling in Javascript",
        date: "6th June 2022",
        description:"What are they and how do we use them to implement optimization in our web applications?",
        read: "https://prashantxwrites.hashnode.dev/debouncing-and-throttling-in-javascript"
    },
    {
        title: "Closures in Javascript",
        date: "16th May 2022",
        description:"What are they and how do we use them to implement optimization in our web applications?",
        read: "https://prashantxwrites.hashnode.dev/closures-in-javascript"
    },
    {
        title: "Memoization in Javascript",
        date: "15th May 2022",
        description:"Short read to describe the optimization technique of Memoization in JavaScript.",
        read: "https://prashantxwrites.hashnode.dev/memoization-in-javascript"
    },
    
    {
        title: "React JS: What has it got to offer?",
        date: "1st August 2021",
        description:"A short read to give the basic idea about the differences and if React is worth the shift.",
        read: "https://prashantxwrites.hashnode.dev/react-and-vanilla-the-journey"
    },
    {
        title: "Responsive Designs 101!",
        date: "31st July 2021",
        description:"Responsive Designs: A short blog for a cleaner CSS design and demystifying the scary media queries.",
        read:"https://prashantxwrites.hashnode.dev/responsive-designs-a-short-blog-for-a-cleaner-css-design"

    }
];

function ProjectList() {
function goTop(){
    scroll.scrollToTop();
}


    return (
        
        <div className="blogList">
            <h1>Blogs</h1>
            <h3>This is my take on web development topics throughout my journey . </h3>
            <div className="listParentB">
                {projects.map(function (item){
                        return(
                            <div className="listCardB">
                            <h4 className="titleB">{item.title}</h4>
                            <small>{item.date}</small>
                            <p>{item.description}</p>
                            <a href={item.read} target="_blank" rel="noreferrer noopener" className="liveProjectB">Read</a>
                        </div>
                        );
                })}
                </div>
                
            <div className="top"
            onClick={goTop}> <FaAngleUp/>
            <span>Top</span>
            </div>

        </div>
    )
}

export default ProjectList
