import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./NavTab.css";
function NavTab() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div className="navParent">
      <div className="navigation">
        <h2> 
          <Link exact to="/" className="link">
            <span>Prashant Singh Chauhan</span>.
          </Link>
        </h2>

        <ul className="nav-pills">
          <li className={splitLocation[1] === "" ? "active" : ""}>
            <Link exact to="/" className="link">
              Home
            </Link>
          </li>

          <li className={splitLocation[1] === "projects" ? "active" : ""}>
            <Link exact to="/projects" className="link">
              Projects
            </Link>
          </li>

          <li className={splitLocation[1] === "blogs" ? "active" : ""}>
            <Link exact to="/blogs" className="link">
              Blogs
            </Link>
          </li>

          <li className={splitLocation[1] === "contact" ? "active" : ""}>
            <Link exact to="/contact" className="link">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavTab;
