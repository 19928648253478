import React from "react";
import "./Contact.css";
import NavTab from "./NavTab";
import BlogList from "./BlogList";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import {
  FaAngleUp,
  FaGit,
  FaGithub,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
function Contact() {
  return (
    <div>
      <Helmet>
        <title>Blogs | Prashant Singh Chauhan</title>
      </Helmet>
      <NavTab />
      <div className="blogList">
        <h1>Contact</h1>
        <h3>
          I am exploring opportunities for collaboration with companies,
          agencies, and individuals, aiming to contribute more than just my
          services. With a background in software development, I am eager to
          join forces to tackle genuine business challenges, leveraging our
          combined expertise and knowledge for optimal outcomes.
        </h3>
        <h3>Feel free to reach out on any platforms below:</h3>
        <div className="contactParentB">
          <div className="cardContainer">
            <div className="marker">|</div>

            <div className="contactCard">
              <div className="contactIcon">
                <IoMdMail size={30} />
              </div>
              <a
                // href=""
                // target="_blank"
                // rel="noreferrer noopener"
                className="contactLink"
              >
                <p className="contactText">
                  <span className="contactLabel">Email: </span>
                  prashant@startech.works
                </p>
              </a>
            </div>
          </div>
          <div className="cardContainer">
            <div className="marker">|</div>
            <div className="contactCard">
              <div className="contactIcon">
                <FaLinkedin size={30} />
              </div>
              <a
                href="https://www.linkedin.com/in/prashantworks47/"
                target="_blank"
                rel="noreferrer noopener"
                className="contactLink"
              >
                <p className="contactText">
                  <span className="contactLabel">LinkedIn: </span>
                  prashantworks47
                </p>
              </a>
            </div>
          </div>
          <div className="cardContainer">
            <div className="marker">|</div>
            <div className="contactCard">
              <div className="contactIcon">
                <FaTwitter size={30} />
              </div>
              <a
                href="https://twitter.com/pareshaaaaan"
                target="_blank"
                rel="noreferrer noopener"
                className="contactLink"
              >
                <p className="contactText">
                  <span className="contactLabel">Twitter: </span>
                  pareshaaaaan
                </p>
              </a>
            </div>
          </div>
          <div className="cardContainer">
            <div className="marker">|</div>
            <div className="contactCard">
              <div className="contactIcon">
                <FaGithub size={30} />
              </div>
              <a
                href="https://github.com/percius47"
                target="_blank"
                rel="noreferrer noopener"
                className="contactLink"
              >
                <p className="contactText">
                  <span className="contactLabel">Github: </span>
                  percius47
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
